import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: '/zhanyu'
  },
  {
    path: "/homepage",
    name: "Home",
    redirect: '/home'
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import("../views/login.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "zhanyuhomepage" */ "../views/gateway/homepage.vue"),
  },
  {
    path: "/zyBrand",
    name: "zyBrand",
    component: () =>
      import(/* webpackChunkName: "gateway" */ "../views/gateway/zy-brand.vue"),
  },
  {
    path: "/industrialPark",
    name: "industrialPark",
    component: () =>
      import(/* webpackChunkName: "gateway" */ "../views/gateway/industrial-park.vue"),
  },
  {
    path: "/expertServices",
    name: "expertServices",
    component: () =>
      import(/* webpackChunkName: "gateway" */ "../views/gateway/expertServices.vue"),
  },
  {
    path: "/expertDetail",
    name: "expertDetail",
    component: () =>
      import(/* webpackChunkName: "gateway" */ "../views/gateway/expert-detail.vue"),
  },
  {
    path: "/parkService",
    name: "parkService",
    component: () =>
      import(/* webpackChunkName: "parkService" */ "../views/gateway/park-service.vue"),
  },
  {
    path: "/newsCenter",
    name: "newsCenter",
    component: () =>
      import(/* webpackChunkName: "gateway" */ "../views/gateway/news-center.vue"),
  },
  {
    path: "/projectCenter",
    name: "/projectCenter",
    component: () =>
      import(/* webpackChunkName: "parkService" */ "../views/gateway/project-center.vue")
  },
  {
    path: "/subPages",
    name: "subPages",
    component: () =>
      import(/* webpackChunkName: "parkService" */ "../views/gateway/sub-pages.vue"),
  },
  {
    path: "/zhanyu",
    name: "zhanyu",
    component: () =>
      import(/* webpackChunkName: "overview" */ "../views/zhanyu/index.vue"),
    redirect: '/zhanyu/overview',
    children: [
      {
        path: "overview",
        name: "overview",
        component: () =>
          import(/* webpackChunkName: "overview" */ "../views/zhanyu/children/overview.vue"),
      },
      {
        path: "breedingSeeding",
        name: "breedingSeeding",
        component: () =>
          import(/* webpackChunkName: "zhanyu" */ "../views/zhanyu/children/breeding-seeding.vue"),
      },
      {
        path: "zyuBrand",
        name: "zyuBrand",
        component: () =>
          import(/* webpackChunkName: "zhanfive" */ "../views/zhanyu/children/zyu-brand.vue"),
      },
      {
        path: "digitalPark",
        name: "digitalPark",
        component: () =>
          import(/* webpackChunkName: "zhanfour" */ "../views/zhanyu/children/digital-park.vue"),
      },
      {
        path: "logisticsTrade",
        name: "logisticsTrade",
        component: () =>
          import(/* webpackChunkName: "zhanyusec" */ "../views/zhanyu/children/logistics-trade.vue"),
      },
      {
        path: "feedProcessing",
        name: "feedProcessing",
        component: () =>
          import(/* webpackChunkName: "zhanyuthr" */ "../views/zhanyu/children/feed-processing.vue"),
      },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // console.log('scrollBehavior')
    document.querySelector('#app').scrollTop = to.meta.savedPosition || 0
  },
});


router.beforeEach((to, from, next) => {
  console.log('to', to)
  if(!localStorage.getItem('sessionId') && to.name !== 'login' && to.path.includes('zhanyu')) {
    next({name: 'login'})
  }

  next()
})

export default router;
